<template>
  <div class="header-box">
    <div class="header fixed">
      <!-- <router-link to="/index" class="caption">
        MOLDING &nbsp;&nbsp;|&nbsp;&nbsp; 武汉模鼎科技有限公司
      </router-link> -->
      <!-- <div style="height: 16px"></div> -->
      <el-menu 
        :default-active="path" 
        class="el-menu-demo" 
        mode="horizontal"
        router
      >
        <el-menu-item index="/index">MOLDING &nbsp;&nbsp;|&nbsp;&nbsp; 武汉模鼎科技有限公司</el-menu-item>
        <el-menu-item index="/product/index">产品介绍</el-menu-item>
        <el-menu-item index="/achievement/index">公司成就</el-menu-item>
        <!-- <el-menu-item index="/test/index">精英</el-menu-item> -->
        <!-- <el-menu-item index="/teamIntro/index">团队</el-menu-item> -->
        <!-- <el-menu-item index="/technicalSupport/index">技术支持</el-menu-item> -->
        <!-- <el-menu-item index="/pressCenter">新闻中心</el-menu-item> -->
        <!-- <el-menu-item index="/moldingCollege">MOLDING学院</el-menu-item> -->
        <el-menu-item index="/contactUs/index">联系我们</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
  },
  data() {
    return {
      path: this.$route.path,
    }
  },
  mounted() {
    
  }
};
</script>

<style lang="less" scoped>
.header-box {
  width: 100%;
  position: sticky;
  top: 0;
  background: white;
  z-index: 9;
  .header {
    // width: 1600px;
    margin: 0px auto;
    // .caption {
      // min-width: 430px;
      // display: block;
      // padding: 20px;
      // font-size: 22px;
      // margin-left: 300px;
      // letter-spacing: 2px
    // }
    ul {
      display: flex;
      justify-content: center;
      li {
        margin-top: 20px;
        height: 70px;
      }
    }
  }
}
.el-menu {
  background: rgb(13, 60, 132);
  // background: linear-gradient(90deg, #011132 0%, #0d3c84 47%, #0d3c84 53%, #011132 100%);
}
.el-menu-item {
  font-size: 20px;
  font-weight: bold;
}
::v-deep .el-menu--horizontal>.el-menu-item.is-active {
  border-bottom: 2px solid white;
  background: rgb(13, 60, 132);
  color: white;
}
.el-menu-item:hover {
  background: transparent !important;
  color: white !important;
}
</style>


