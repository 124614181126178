<template>
  <el-container direction="vertical">
    <Header></Header>
    <router-view></router-view>
    <!-- <consult/> -->
    <Footer></Footer>
  </el-container>
</template>

<script>
import Header from "./subview/header.vue"
import Footer from "./subview/footer.vue"
// import Consult from "@/components/consult/index.vue"

export default {
  components: {
    Header,
    Footer,
    // Consult
  },
  data() {
    return {

    }
  },
  created() {
    console.log(this.$route.path)
  },
}
</script>

<style lang="scss" scoped>

</style>