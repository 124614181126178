<template>
  <div class="footer">
    <div class="logo">
      <ul>
        <li>
          <router-link to="/index" class="caption">
            MOLDING <br> 武汉模鼎科技有限公司
          </router-link>
        </li>
      </ul>
    </div>
    <div class="menu">
      <ul v-for="(item, index) in footer_info" :key="index">
        <li v-for="(lis, idx) in item.lis_info" :key="idx">
          <router-link :to="lis.link_url">
            <div class="clear-box">
              <img :src="lis.img_url" alt="" width="40"/>
            </div>
            <p>{{ lis.name }}</p>
          </router-link>
        </li>
      </ul>
    </div>
    <div
      style="
        position: absolute;
        text-align: center;
        bottom: 30px;
        margin: 0 auto;
        width: 100%;
        color: #5c6b77;
      "
    >
      <a
        target="_blank"
        style="color: #5c6b77"
        href="https://beian.miit.gov.cn/"
        >鄂ICP备2021009988号-2</a
      >&nbsp; @2020-2030 武汉模鼎科技有限公司
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      footer_info: [
        {
          lis_info: [
            {
              name: "关于我们",
              link_url: "/index",
              img_url: require("@/assets/images/footer/gywm.png"),
            },
          ],
        },
        {
          lis_info: [
            {
              name: "产品介绍",
              link_url: "/product",
              img_url: require("@/assets/images/footer/cpjs.png"),
            },
            // { name: "注塑装备虚拟操作", link_url: "/product/pseudo-operation" },
            // { name: "注塑模具智能试模", link_url: "/product/test-mode" },
            // { name: "成型过程智能监控", link_url: "/product/intelligent-monitoring" },
            // { name: "产品质量智能检测", link_url: "/product/intelligent-detection" },
          ],
        },
        {
          lis_info: [
            {
              name: "公司成就",
              link_url: "/achievement",
              img_url: require("@/assets/images/footer/gscj.png"),
            },
          ],
        },
        // {
        //   lis_info: [
        //     {
        //       name: "团队简介",
        //       link_url: "/teamIntro",
        //       img_url: require("@/assets/images/footer/tdjj.png"),
        //     },
        //   ],
        // },
        // {
        //   lis_info: [
        //     {
        //       name: "技术支持",
        //       link_url: "/technicalSupport",
        //       img_url: require("@/assets/images/footer/jszc.png"),
        //     },
        //   ],
        // },
        {
          lis_info: [
            {
              name: "联系我们",
              link_url: "/contactUs",
              img_url: require("@/assets/images/footer/lxwm.png"),
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.footer {
  display: flex;
  justify-content: center;
  height: 300px;
  background: linear-gradient(
    90deg,
    #011132 0%,
    #0d3c84 47%,
    #0d3c84 53%,
    #011132 100%
  );
  position: relative;
  .logo {
    width: 35%;
    height: 220px;
    border-bottom: 1px solid rgba(255,255,255,.1);
    border-right: 1px solid rgba(255,255,255,.1);
    ul {
      width: 100%;
      margin: 70px 260px;
      li a {
        color: white;
        font-size: 28px;
      }
    }
  }
  .menu {
    width: 65%;
    height: 220px;
    display: flex;
    justify-content: center;
    align-content: center;
    border-bottom: 1px solid rgba(255,255,255,.1);
    ul {
      min-width: 130px;
      margin: 55px 10px;
      li {
        margin-bottom: 10px;
        .clear-box {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 66px;
          height: 66px;
          border-radius: 10px;
          background: rgba(255,255,255,.2);
          margin-left: 50%;
          transform: translate(-50%);
        }
        p {
          color: white;
          margin-top: 15px;
        }
      }
    }
  }
}
</style>
